import { useEffect, useState } from 'react';
import { useOktaAuthentication } from '.';
import { fetchUser } from '../dao/user';

// This hook deliberately uses the access-token to show how to add
// authentication to the requests. (if all requests need authentication,
// we can instead add it to the api.js#runRequestModifers)
export function useUser(id) {
    const [ user, setUser ] = useState(null);
    const accessToken = useOktaAuthentication();

    useEffect(() => {
        if(id && accessToken) {
            fetchUser(id, accessToken).then(
                (response) => {
                    // response is already the parsed JSON body, modify next line
                    // to match correct property of (or whole) response
                    // console.log('result from fetching all users', response)
                    setUser(response);
                },
            );
        }
    }, [ accessToken, id ]);

    return user;
}