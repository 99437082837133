import React from 'react';

import { SecureRoute } from '@okta/okta-react';
const AdminRoute = (props) => {
  const { component: Component, ...rest } = props;

  return (
      <SecureRoute
        { ...rest }
        render={ (routeProps) => (
            <Component { ...routeProps } />
          ) }
      />
  );
};

export default AdminRoute;
