const startForwardSlashRE = /^\//;
const endForwardSlashRE = /\/$/;

/**
 * This is a simple join, similar to Node's path.join(), appending paths
 * with a forward slash `/` between. It's intended use is for creating API
 * paths, without having to care about superfluous forward slashes, for
 * instance:
 *
 * path.simpleJoin('http://my.domain/', '/api'); => 'http://my.domain/api'
 *
 * It is simple in that it only uses forward slash, but also in that it does
 * not attempt to clean up relative path elements, or absolute ones for that
 * matter.
 *
 * @param {string} root The first path
 * @param  {...string} paths The path(s) you want to append
 * @returns {string} a single string with all paths appended
 */
export function simpleJoin(root, ...paths) {
  if (paths.length) {
    const path = paths.shift().replace(startForwardSlashRE, '');
    const cleanRoot = root.replace(endForwardSlashRE, '');
    return simpleJoin(`${cleanRoot}/${path}`, ...paths);
  }
  return root;
}
