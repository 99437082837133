import { API_ROOT } from '../environment';
import { simpleJoin } from '../utils/path';
import { get, post, del } from './api';

const API_PATH_APPLICATIONS = '/applications';
const API_URL_APPLICATIONS = simpleJoin(API_ROOT, API_PATH_APPLICATIONS);

// Using the special Okta endpoint due to token verification.
export async function fetchAllApplications(accessToken, isAdmin) {
  // If isAdmin is true, the request comes from the admin part,
  // which has a different auth method.
  const endpoint = isAdmin
    ? API_URL_APPLICATIONS + '-okta'
    : API_URL_APPLICATIONS;
  return get(
    simpleJoin(endpoint),
    // setting authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function createApplication(body, accessToken) {
  return post(
    simpleJoin(API_URL_APPLICATIONS),
    body,
    // setting authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function deleteApplication(id, accessToken) {
  return del(
    simpleJoin(API_URL_APPLICATIONS, id),
    {},
    // setting authentication header
    (request) => request.set('Authorization', accessToken),
  );
}
