import { API_ROOT } from '../environment';
import { simpleJoin } from '../utils/path';
import { post } from './api';

const API_PATH_EMAIL = '/authorization';
const API_URL_EMAIL = simpleJoin(API_ROOT, API_PATH_EMAIL);

export async function sendEmail(userId, applicationId, accessToken) {
    const response = post(simpleJoin(`${API_URL_EMAIL}?userId=${userId}&applicationId=${applicationId}`),
        (request) => request.set('Authorization', accessToken),
    )
    return response;
}