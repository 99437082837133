import React, { useEffect } from 'react';
import { useAuthentication, useI18n } from '../../hooks';
import './Faq.css';
import faq from '../../media/faq-desk-1920x720.jpg';

export default function Faq() {
    const { i18n } = useI18n();
    const { user } = useAuthentication()

    const questions = [
        {
            'question': i18n('faq.questions.q1'),
            'answer': i18n('faq.questions.a1'),
            'link': 'https://my.cat.com/wps/portal/customer/Auxiliary/Forgot-Username/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLT1cTDzcgw18_YNcjA0c_QKMvIOMDIy9fU31w_EqcDfRjyJGvwEO4GhAWH8UXiUgF4AV4LGiIDc0wiDTUREAKrV97Q!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/',
            'linkText': i18n('faq.forgot_pw'),
        },
        {
            'question': i18n('faq.questions.q2'),
            'answer': i18n('faq.questions.a2'),
            'link': 'https://my.cat.com/wps/portal/customer/Auxiliary/Forgot-Username/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLT1cTDzcgw18_YNcjA0c_QKMvIOMDIy9fU31w_EqcDfRjyJGvwEO4GhAWH8UXiUgF4AV4LGiIDc0wiDTUREAKrV97Q!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/',
            'linkText': i18n('faq.forgot_un'),
        },
        {
            'question': i18n('faq.questions.q3'),
            'answer': i18n('faq.questions.a3'),
            'link': 'https://my.cat.com/wps/portal/customer/Auxiliary/Create-Account/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLT1cTDzcgw18_YNcjA0c_QKMvIOMDIy9HU31w_EqcDfRjyJGvwEO4GhAWH8UXiUgF4AV4LEiODVPvyA3NMIgy0QRACtuBNY!/dz/d5/L2dBISEvZ0FBIS9nQSEh/',
            'linkText':i18n('faq.cws'),
        },
        {
            'question': i18n('faq.questions.q4'),
            'answer': i18n('faq.questions.a4'),
            'link': '',
        },
    ];

    useEffect(() => {
        [ ...document.getElementsByClassName('faq-question') ].forEach((element) => {
            element.style.maxHeight = `${element.scrollHeight + 30}px`
        })
    })

    const toggleQuestion = (id) => {
        document.getElementById(id).classList.toggle('collapsed');
    };

    const renderQuestion = ({ question, answer, link, linkText }, index, path) => {
        const question_id = `faq-question-${path}-${index}`;
        return (
            <li id={question_id} key={`faq-${index}`} className={'faq-question collapsed'}
                onClick={() => toggleQuestion(question_id)}>
                <span className="row justify-content-between">
                    <h4>Q: {question}</h4>
                    <i className="fa fa-chevron-down"/>
                    <i className="fa fa-chevron-up"/>
                </span>
                <p>{answer}</p>
                { link &&
                <a href={link} rel='noreferrer noopener nofollow' target='_blank'>{linkText}</a>
                }
            </li>
        );
    };

    return (
        <div id={'faq-page'}>
            <div id={'faq-banner'}>
                <img src={faq} alt={'faq'}/>
                <div className={'container'}>
                    <div id={'faq-overlay'}>
                        <span id={'faq-myponpower'}>My Pon Power</span>

                        {user.given_name
                        ? <h1>{i18n('faq.how_can_i_help')}, {user.given_name}?</h1>
                        : <h1>{i18n('faq.how_can_i_help')} ?</h1>
                        }
                        <p> {i18n('faq.faq_explanation')}</p>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <h1>{i18n('faq.title')}</h1>
                <ul className={'faq-questions'}>
                    {questions.map((question, index) => renderQuestion(question, index, 'general'))}
                </ul>

                <div id="faq-cant-find-answer" className="flex-column">
                    <h1>{i18n('faq.cant_find_answer.title')}</h1>
                    <p>{i18n('faq.cant_find_answer.subtitle')}</p>
                    <p>{i18n('faq.cant_find_answer.info')} <a href="mailto:info@pon-cat.com">info@pon-cat.com </a> / 078 - 642 0560</p>
                </div>
            </div>
        </div>
    );
}