import React from 'react';

/**
 * @param {{
 *   className: String,
 *   stack: number,
 * }} arg React props 
 * @returns {JSX.Element} ...
 */
export default function CheckedIcon({ className = 'card-icon', stack = 2 }) {
    return (
        <span className={`fa-stack fa-xs ${className}`}>
          <i className={`fa fa-circle fa-stack-${stack}x icon-background`}/>
          <i className="fa fa-check fa-stack-1x icon-foreground"/>
        </span>
    );
}
