import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useAuthentication } from '../../hooks';
import {
  TokenRequest,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  AuthorizationNotifier,
  FetchRequestor, LocalStorageBackend, DefaultCrypto,
} from '@openid/appauth';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { NoHashQueryStringUtils } from '../../utils/noHashQueryStringUtils';
import Login from '../home/Home';
import { BASE_URL, CAT_URL, CLIENT_ID } from '../../environment';
import { updateUserLoginCount } from '../../dao/user';

const Callback = (props) => {

    const history = useHistory();

    const { setUser } = useAuthentication();

    const [ error, setError ] = useState(null);    // eslint-disable-line no-unused-vars
    const [ code, setCode ] = useState(null);

    const updateUser = async (accessToken, refreshToken) => {
        const decoded_token = jwt_decode(accessToken);

        setUser({
            given_name: decoded_token.given_name,
            family_name: decoded_token.family_name,
            email: decoded_token.email_address,
            catloginid: decoded_token.catloginid.toLowerCase(),
            refreshToken,
            accessToken,
        });

        // Set the user for Sentry so we have the user info available in Sentry
        Sentry.setUser({
            username: decoded_token.catloginid.toLowerCase(),
            email: decoded_token.email_address,
        });

        updateUserLoginCount(decoded_token.email_address, accessToken);
    };

    useEffect(() => {
        const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
        const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
        const notifier = new AuthorizationNotifier();
        authorizationHandler.setAuthorizationNotifier(notifier);
        notifier.setAuthorizationListener((request, response, error) => {
            if (response) {
                let extras = null;
                if (request && request.internal) {
                    extras = {};
                    extras.code_verifier = request.internal.code_verifier;
                }

                const tokenRequest = new TokenRequest({
                    client_id: CLIENT_ID,
                    redirect_uri: `${BASE_URL}/callback`,
                    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                    code: response.code,
                    extras,
                });

                AuthorizationServiceConfiguration.fetchFromIssuer(`${CAT_URL}`, new FetchRequestor())
                    .then((oResponse) => {
                        const configuration = oResponse;
                        return tokenHandler.performTokenRequest(configuration, tokenRequest);
                    })
                    .then((oResponse) => {

                        return updateUser(oResponse.accessToken, oResponse.refreshToken);
                    })
                    .then(() => {
                        history.replace('/home');
                    })
                    .catch(oError => {
                        console.error(oError);
                    });
            }
        });

        const params = new URLSearchParams(props.location.search);
        setCode(params.get('code'));

        if (!code) {
            setError('Unable to get authorization code');
            return;
        }

        authorizationHandler.completeAuthorizationRequestIfPossible();
    }, [ code, props ]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Login />
    )
}

export default Callback;
