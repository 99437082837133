import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';

/**
 * @returns {AuthUser|null} Details about the user...
 */
export const useOktaAuthentication = () => {
  const { oktaAuth } = useOktaAuth();
  const [ accessToken, setAccessToken ] = useState('');

  useEffect(() => {
    setAccessToken(oktaAuth.getAccessToken());
  }, [ oktaAuth ]);

  return accessToken;
};
