import React from 'react';
import './sidebar.css';
import { useAuthentication, useI18n } from '../../hooks';
import contactButtonIcon from '../../media/contact_icon.png'

import callIcon from '../../media/call.svg';
import mailIcon from '../../media/arrow.svg';

export function toggle() {
    document.documentElement.classList.toggle('sidebar--visible');
}

export function ContactButton() {
    return (
            <img id="contact-sticky" alt="contact" src={contactButtonIcon} onClick={toggle}/>
    )
}

export default function Sidebar() {
    const { user } = useAuthentication();
    const { i18n } = useI18n();

    return (
        <div id="sidebar">
            <div id="sidebar_overlay" onClick={toggle}/>
            <div id="sidebar_content">
                <div className="container">
                    <div id="sidebar_control">
                        <i className="fa fa-times overlay-close-button" onClick={toggle}/>
                    </div>
                    <div className="content">
                        <h3>{i18n('sidebar.call_us')} {user.given_name ? `${user.given_name} ` : ''}?</h3>
                        
                        <p className="subtitle"> {i18n('sidebar.subtitle')} </p>
                        <div className="call-pon-power">
                            <article className="call-pon-power__method">
                                <h4>{i18n('sidebar.phone')}</h4>
                                <a href="tel:+3178642 0560">
                                    <img src={callIcon} alt='phone icon'/>
                                    <span> 078 - 642 0560</span> 
                                </a>
                                <p>{i18n('sidebar.time')} </p>
                            </article>
                            <article className="call-pon-power__method">
                                <h4>{i18n('sidebar.email')}</h4>
                                <a href="mailto:info@pon-cat.com">
                                    <img src={mailIcon} alt='envelop icon'/>
                                    <span> info@pon-cat.com</span>
                                </a>
                            </article>
                        </div>            
                    </div>
                </div>
            </div>
        </div>
    );
}