import React from 'react';

const FORM_FIELDS = {
    email: 'Email',
    cwsid: 'CWS ID',
    lastname: 'Last Name',
    firstname: 'First Name',
    ucid: 'UCID',
    dcn: 'DCN',
    company: 'Company',
    industry: 'Industry',
};

const DISABLE_ON_EDIT = [ 'email' ];

function FormField({
    label,
    name,
    ...props
}) {
    return <tr>
        <td>
            {label}
        </td>
        <td>
            <input type="text" id={name} name={name} {...props}/>
        </td>
    </tr>;
}

export default function UserForm({
    edit = false,
    handleChange,
    values,
}) {
    return <div className="col-12 col-md-6">
        <h2>General details</h2>
        <table>
            <tbody>
                {Object.keys(FORM_FIELDS).map((key) => (<FormField key={key} { ...{
                    label: FORM_FIELDS[key],
                    name: key,
                    onChange: handleChange,
                    defaultValue: values.general[key],
                    disabled: edit && DISABLE_ON_EDIT.includes(key),
                }}/>))}
            </tbody>
        </table>
    </div>;
}