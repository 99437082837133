import React from 'react';
import './footer.css';
import { useI18n } from '../../hooks';

function ExternalLink({ translationKey }) {
    const { i18n } = useI18n();
    return (
        <a href={i18n(`${translationKey}.link`)} rel="noreferrer noopener" target="_blank">{i18n(`${translationKey}.description`)}</a>
    )
}

function FooterCopyright() {
    const { i18n } = useI18n();
    const legalLinks = [ 'privacy', 'conditions', 'cookies' ];

    return (<ul className="footer__legal-links">
        <li className="footer__legal-link">
            {i18n('footer.copyright', new Date().getFullYear())}
        </li>
        {legalLinks.map((i18nKey) => (<li className="footer__legal-link" key={i18nKey}>
            <ExternalLink translationKey={`footer.${i18nKey}`} />
        </li>))}
        <li className="footer__legal-link footer__legal-link--dealer">
            {i18n('footer.segway')}&nbsp;
            <ExternalLink translationKey='footer.dealer' />
        </li>
    </ul>)
}

export default function Footer() {
    return (
        <footer className="navbar-custom">
            <div className="container-lg">
                <FooterCopyright />

                <h6 className="font-weight-bold">Pon Power</h6>

                <p>
                    <a href="https://www.facebook.com/ponpowernl/" rel="noreferrer noopener" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.linkedin.com/company/pon-power/" rel="noreferrer noopener" target="_blank"><i className="fab fa-linkedin-in" ></i></a>
                    <a href="https://www.youtube.com/user/PonPowerTV/" rel="noreferrer noopener" target="_blank"><i className="fab fa-youtube" ></i></a>
                    <a href="https://www.instagram.com/pon_power_nl/" rel="noreferrer noopener" target="_blank"><i className="fab fa-instagram" ></i></a>
                </p>
            </div>
        </footer>
    )
}
