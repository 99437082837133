import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CheckedIcon from '../../components/CheckedIcon';
import {
  useAuthentication,
  useOktaAuthentication,
  useApplications,
  useI18n,
  useUserPermissions,
} from '../../hooks';
import { sendEmail } from '../../dao/email';
import useLocalStorage from '../../hooks/useLocalStorage';
import Modal from 'react-bootstrap/Modal';
import './Home.css';

function Application({ application, showHidden, userObj }) {
  const { i18n } = useI18n();
  const accessToken = useOktaAuthentication();
  const userCanAccess = userObj.allowed;
  const [ applicationRequested, setApplicationRequested ] = useLocalStorage(
    'requested_applications',
    [],
  );

  const requestAccess = async (app) => {
    const request = window.confirm(i18n('home.access.access_request_prompt'));
    if (request === true) {
      // Add application to list of requested applications in local storage
      setApplicationRequested([ ...applicationRequested, app.target.id ]);

      await sendEmail(userObj.id, app.target.id, accessToken).then(
        (response) => {
          if (response.status === 200) {
            window.alert(i18n('home.access.access_request_success'));
          }
        },
      );
    }
  };

  // get applications of user
  // check if this application id is listed
  let isVisible = Object.values(userCanAccess).includes(application.target.id);

  // if showHidden is true, show all unavailable applications
  // otherwise show only the available applications
  if ((!isVisible && !showHidden) || (isVisible && showHidden)) {
    return null;
  }

  const applicationClassName = [
    'application-block',
    application.new && 'new-application',
    !isVisible && 'application-blok--invisible',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={applicationClassName}>
      <figure
        className="application__media"
        alt={application.target.name}
        style={{
          backgroundImage: `url(${application.image})`,
        }}
      />

      <h3 className="h4">{application.target.name}</h3>

      <ul>
        {application.notes.map((note, index) => {
          return (
            <li key={index}>
              <CheckedIcon className="application-check-badge" stack={1} />
              <span>{note}</span>
            </li>
          );
        })}
      </ul>

      <div className="row link-wrapper non-opaque">
        {/* Button to open application */}
        {isVisible && (
          <a
            className="btn"
            disabled={!isVisible}
            id={application.target.id}
            href={application.target.url}
            target="_blank"
            rel="noreferrer nofollow noopener"
          >
            {application.target.name}{' '}
            <i className="fa fa-regular fa-arrow-right" />
          </a>
        )}

        {/* Button to request access */}
        {!isVisible && (
          <button
            className={`btn non-opaque ${
              applicationRequested.includes(application.target.id)
                ? 'disabled'
                : ''
            }`}
            disabled={applicationRequested.includes(application.target.id)}
            onClick={() => {
              requestAccess(application);
            }}
          >
            {applicationRequested.includes(application.target.id) && (
              <>
                {i18n('home.access.access_requested')}{' '}
                <i className="fa fa-regular fa-check" />
              </>
            )}
            {!applicationRequested.includes(application.target.id) && (
              <>
                {i18n('home.access.request')}{' '}
                <i className="fa fa-regular fa-arrow-right" />
              </>
            )}
          </button>
        )}
      </div>

      {application.new && (
        <span className="fa-stack new-application-badge">
          <i className="fa fa-circle fa-stack-1x" />
          <span className="fa-stack-1x"> {i18n('home.new')} </span>
        </span>
      )}
    </div>
  );
}

export default function Home() {
  const [ showFirstLoginPopup, setShowFirstLoginPopup ] = useState(false);
  const [ userPermissions, setUserPermissions ] = useState([]);
  const { user, signed_in, accessToken } = useAuthentication();
  const { i18n } = useI18n();
  const applications = useApplications(accessToken);

  const [ userObj, userObjIsLoading ] = useUserPermissions(
    user.catloginid,
    accessToken,
  );

  useEffect(() => {
    if (userObj?.allowed) {
      setUserPermissions(userObj?.allowed);
    }
    if (!userObjIsLoading && userObj === null) {
      setShowFirstLoginPopup(true);
    }
  }, [ userObjIsLoading, userObj ]);

  const handleClose = () => setShowFirstLoginPopup(false);

  return (
    <div id="home_page">
      {signed_in && (
        <div id="welcome_message">
          <div className="container">
            <p>{i18n('home.welcome.message')}</p>
          </div>
        </div>
      )}
      <article className="container">
        <div id="home_header" className="row">
          <h1 className="home_header__title col-md-auto">
            {i18n('home.my_dashboard', user.given_name)}
          </h1>
          <div
            id="help_me_now"
            className="home_header__help help_me_now_home p-0"
          >
            <span className="fa-stack">
              <i className="fa fa-comment fa-stack-1x" />
              <i className="fa fa-question fa-stack-1x" />
            </span>
            <Link id="help_me_text" to="/faq">
              {i18n('home.help_me_now')}
            </Link>
          </div>
        </div>

        {/* Available applications */}
        <div className="row home-page__subtitle">
          <h2 className="col-9 h4">
            <i className="fa fa-regular fa-arrow-down" />{' '}
            {i18n('home.available_applications')}
          </h2>
        </div>
        {userObj?.allowed && (
          <div id="applications" className="row">
            {applications && userPermissions && userPermissions.length ? (
              applications.map((application, index) => (
                <Application
                  key={index}
                  application={application}
                  userObj={userObj}
                />
              ))
            ) : (
              <p>No applications found</p>
            )}
          </div>
        )}

        {/* Non available applications */}
        <div className="row home-page__subtitle">
          <h2 className="col-9 h4">
            <i className="fa fa-regular fa-arrow-down" />{' '}
            {i18n('home.non_available_applications')}
          </h2>
        </div>
        {userObj?.allowed && (
          <div id="applications" className="row">
            {applications && userPermissions && userPermissions.length ? (
              applications.map((application, index) => (
                <Application
                  key={index}
                  application={application}
                  showHidden={true}
                  userObj={userObj}
                />
              ))
            ) : (
              <p>No applications found</p>
            )}
          </div>
        )}

        <Modal
          className="first-login-modal"
          show={showFirstLoginPopup}
          onHide={handleClose}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <p>
              {i18n('home.first_login_popup_body')}{' '}
              <a href="tel:+31786420420">+31 (0)78 642 0420</a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={handleClose}>
              {i18n('home.first_login_popup_button')}
            </button>
          </Modal.Footer>
        </Modal>
      </article>
    </div>
  );
}
