import React, { useState } from 'react';
import { useOktaAuthentication, useDisplayMessageContext } from '../../hooks';
import { createUser } from '../../dao/user';
import { Link, useHistory } from 'react-router-dom';
import './Admin.css';
import UserForm from '../../components/admin/UserForm';

export default function AddUser() {
    const accessToken = useOktaAuthentication();

    const {
        setRequestResult,
        setOpenMessage,
      } = useDisplayMessageContext();

    const [ values, setValues ] = useState(
        {
            general: {
                cwsid: '',
                email: '',
                lastname: '',
                firstname: '',
                ucid: '',
                dcn: '',
                company: '',
                industry: '',
            },
            allowed: [],
        },
    );

    const handleChange = ({
        target,
    }) => {
        setValues({ ...values, general: {
            ...values.general,
            [target.id]: target.value,
        } });
    }

    function handleRequestError() {
        setRequestResult({
            title: 'Error',
            message: 'An unexpected error occured',
            type: 'failure',
            time: 5000,
        });
        setOpenMessage(true);
    }

    let history = useHistory();
    const handleSubmit = event => {
        event.preventDefault();

        const body = {
            ...values.general,
            enabled: true,
            allowed: [],
        }

        if(accessToken) {
            createUser(body, accessToken).then(
                (response) => {
                    if(201 === response.status && response.userid) {
                        setRequestResult({
                            title: 'success',
                            message: response.message,
                            type: 'success',
                        });
                        setOpenMessage(true);
                        history.push(`/admin/user/${response.userid}`);
                    } else {
                        setRequestResult({
                            title: 'failed',
                            message: response.message,
                            type: 'failure',
                        });
                    }
                },
            ).catch(handleRequestError);
        }
    }

    return (
        <div id="admin_page">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12">
                        <Link
                            role="button" to="/admin"
                        >
                            <i className="fas fa-angle-left"></i>
                            Back to all users
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h1>Add user</h1>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <UserForm {...{
                            handleChange,
                            values,
                        }} />
                    </div>
                    <div className="action-row">
                        <button type="submit" className="btn btn-yellow" value="Submit">
                            <i className="fas fa-save"></i>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}