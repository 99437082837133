import * as Sentry from '@sentry/browser';
import { API_ROOT } from '../environment';
import { simpleJoin } from '../utils/path';
import { get, patch, post, del } from './api';

// API end-point is assumed to be [API_ROOT]/users/[uid]
const API_PATH_USERS = '/users';
const API_URL_USERS = simpleJoin(API_ROOT, API_PATH_USERS);
const API_PATH_PERMISSION = '/permissions';
const API_URL_PERMISSION = simpleJoin(API_ROOT, API_PATH_PERMISSION);
const API_PATH_LOGIN_COUNT = '/audit/logins';
const API_URL_LOGIN_COUNT = simpleJoin(API_ROOT, API_PATH_LOGIN_COUNT);

export async function fetchUser(id, accessToken) {
  return get(
    simpleJoin(API_URL_USERS, id),
    // Example of how to add authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function fetchAllUsers(accessToken) {
  return get(
    simpleJoin(API_URL_USERS),
    // Example of how to add authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function fetchUserPermissions(catloginid, accessToken) {
  const result = get(
    simpleJoin(API_URL_PERMISSION, `?user=${catloginid}`),
    // Example of how to add authentication header
    (request) => request.set('Authorization', accessToken),
  );

  // When the result is null, the user is not found in the database.
  // So a message with the error is sent to Sentry, including the catloginid and emailaddress of that user.
  // Which is set in the user object in Sentry on login and cleaned on logout.
  if (result === null) {
    Sentry.captureMessage('No user object found');
  }
  return result;
}

export async function updateUser(id, body, accessToken) {
  return patch(
    simpleJoin(API_URL_USERS, id),
    body,
    // Example of how to add authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function createUser(body, accessToken) {
  return post(
    simpleJoin(API_URL_USERS),
    body,
    // Example of how to add authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function deleteUser(id, accessToken) {
  return del(
    simpleJoin(API_URL_USERS, id),
    {},
    // Example of how to add authentication header
    (request) => request.set('Authorization', accessToken),
  );
}

export async function updateUserLoginCount(email, accessToken) {
  return patch(
    simpleJoin(API_URL_LOGIN_COUNT, `?user=${email}`),
    {},
    (request) => request.set('Authorization', accessToken),
  );
}
