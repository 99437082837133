import { useEffect, useState } from 'react';
import { useI18n } from './useI18n';

import catVisionLink from '../media/cat-visionlink.jpg';
import pcc from '../media/pcc.jpeg';
import olie from '../media/olie.png';
import technicalPortal from '../media/technical-portal.jpg';
import catRam2 from '../media/cat-ram2.jpg';
import catRfv2 from '../media/cat-rfv2.jpg';
import catTip from '../media/cat-tip.jpg';
import catSis from '../media/cat-sis.jpg';
import catInspect from '../media/cat-inspect.png';
import channel1 from '../media/channel-1.png';

import { fetchAllApplications } from '../dao/application';

const apps = [
  {
    title: 'CAT VisionLink', // My.cat.com is changed to CAT VisionLink.
    id: 'my-cat-com', // ID is not changed otherwise access to the tile needs to be a updated of every user.
    img: catVisionLink,
    notes1: 'apps.visionlink.1',
    notes2: 'apps.visionlink.2',
    notes3: 'apps.visionlink.3',
    url: 'https://vl.cat.com/visionlink',
  },
  {
    title: 'Parts.cat.com',
    id: 'parts-cat-com',
    img: pcc,
    notes1: 'apps.pcc.1',
    notes2: 'apps.pcc.2',
    notes3: 'apps.pcc.3',
    url: 'https://parts.cat.com',
  },
  {
    title: 'SOS online',
    id: 'psos-online',
    img: olie,
    notes1: 'apps.sos.1',
    notes2: 'apps.sos.2',
    notes3: 'apps.sos.3',
    url: 'https://soswebmc.cat.com/cat-sos/dashboard',
  },
  {
    title: 'Technical Portal',
    id: 'cat-technical-portal',
    img: technicalPortal,
    notes1: 'apps.catTP.1',
    notes2: 'apps.catTP.2',
    notes3: 'apps.catTP.3',
    url: 'https://www.pon-cat.com/login/authenticate/cws',
  },
  {
    title: 'Remote Asset Monitoring',
    id: 'remote-asset-monitoring',
    img: catRam2,
    notes1: 'apps.ram.1',
    notes2: 'apps.ram.2',
    notes3: 'apps.ram.3',
    url: 'https://ram.cat.com/Thingworx/Runtime/index.html?mashup=CAT.RAMUI.MU',
  },
  {
    title: 'Remote Fleet Vision',
    id: 'remote-fleet-vision',
    img: catRfv2,
    notes1: 'apps.rfv.1',
    notes2: 'apps.rfv.2',
    notes3: 'apps.rfv.3',
    url: 'https://catremotefleetvision.com',
  },
  {
    title: 'TIP',
    id: 'tip',
    img: catTip,
    notes1: 'apps.tip.1',
    notes2: 'apps.tip.2',
    notes3: 'apps.tip.3',
    url: 'https://tip.cat.com/Search.aspx',
  },
  {
    title: 'SIS2',
    id: 'sis2',
    img: catSis,
    notes1: 'apps.sis2.1',
    notes2: 'apps.sis2.2',
    notes3: 'apps.sis2.3',
    url: 'https://sis2.cat.com/#/',
  },
  {
    title: 'CAT Inspect',
    id: 'cat-inspect',
    img: catInspect,
    notes1: 'apps.inspect.1',
    notes2: 'apps.inspect.2',
    notes3: 'apps.inspect.3',
    url: 'https://catinspectweb.cat.com',
  },
  {
    title: 'CAT eLearning',
    id: 'cat-e-learning',
    img: channel1,
    notes1: 'apps.catE.1',
    notes2: 'apps.catE.2',
    notes3: 'apps.catE.3',
    url: 'https://channel1.mediaspace.kaltura.com/user/login',
  },
];

function getApp(apps, id) {
  return apps.find((el) => el.id.indexOf(id) !== -1);
}

function translateApps(applications, i18n) {
  if (applications) {
    return applications.map((app) => {
      const applicationFound = getApp(apps, app.id);
      if (applicationFound) {
        return {
          image: applicationFound.img,
          notes: [
            i18n(applicationFound.notes1),
            i18n(applicationFound.notes2),
            i18n(applicationFound.notes3),
          ],
          target: {
            name: applicationFound.title,
            id: applicationFound.id,
            url: applicationFound.url,
          },
          new: false,
        };
      } else {
        return false;
      }
    });
  }
}

export function useApplications(accessToken, isAdmin = false) {
  const { i18n } = useI18n();
  const [ applications, setApplications ] = useState(null);

  useEffect(() => {
    if (accessToken) {
      fetchAllApplications(accessToken, isAdmin).then((response) => {
        setApplications(translateApps(response, i18n));
      });
    }
  }, [ i18n, accessToken, isAdmin ]);

  return applications;
}
