import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { stringify } from 'querystring';
import { BASE_URL, CAT_URL, CLIENT_ID } from '../../src/environment';
import log from 'loglevel';
import * as Sentry from '@sentry/browser';

import {
    AuthorizationServiceConfiguration,
    RedirectRequestHandler,
    FetchRequestor, LocalStorageBackend, DefaultCrypto, AuthorizationRequest,
} from '@openid/appauth';

import { NoHashQueryStringUtils } from '../utils/noHashQueryStringUtils';
import useLocalStorage from './useLocalStorage';
import { hashFnv32a } from '../utils/hashString';

const AuthenticationContext = React.createContext();

const initialState = {
    given_name: process.env.REACT_APP_GIVEN_NAME,
    family_name: process.env.REACT_APP_FAMILY_NAME,
    email: process.env.REACT_APP_EMAIL,
    code: process.env.REACT_APP_CODE,
    accessToken: process.env.REACT_APP_ACCESSTOKEN,
    refreshToken: process.env.REACT_APP_REFRESHTOKEN,
};

export const useAuthentication = () => {
    const context = React.useContext(AuthenticationContext);

    if (context === undefined) {
        throw new Error(
            '`useAuthentication` must be used with a `AuthenticationProvider`',
        );
    }
    return context;
};

export const AuthenticationProvider = ({ children }) => {
    const [ user, setUser ] = useLocalStorage('user', initialState);
    const [ code, setCode ] = useState(null);
    const [ userHash, setUserHash ] = useState(null);

    useEffect(() => {
        if (user?.email) {
            setUserHash(hashFnv32a(user.email, 32))
        }
    }, [ user.email ])
    const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());

    const signed_in = !!user.refreshToken

    const setRefreshToken = (refreshToken) => setUser({ ...user, refreshToken });
    const setAccessToken = (accessToken) => setUser({ ...user, accessToken });
    const accessToken = user.accessToken;
    const refreshToken = user.refreshToken;

    const authorize = () => {
        AuthorizationServiceConfiguration.fetchFromIssuer(`${CAT_URL}`, new FetchRequestor())
            .then((response) => {
                const authRequest = new AuthorizationRequest({
                    client_id: CLIENT_ID,
                    redirect_uri: `${BASE_URL}/callback`,
                    scope: 'openid',
                    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
                    state: '123456',
                    extras: { 'pfidpadapterid': 'OAuthAdapterBasicIdentity' },
                });
                authorizationHandler.performAuthorizationRequest(response, authRequest);
            })
            .catch(error => {
                log.error(error);
            });
    };

    const logout = async () => {
        await axios.post(`${CAT_URL}oauth2/v2.0/logout`, stringify({
                'token': refreshToken,
                'token_type_hint': 'refresh_token',
                'client_id': CLIENT_ID,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        ).then(() => {
                setUser(initialState);

                // Remove the user object for Sentry on logout
                Sentry.configureScope(scope => scope.setUser(null));
            },
        );

    };

    const context = {
        user,
        userHash,
        signed_in,
        authorize,
        logout,
        setRefreshToken,
        setAccessToken,
        accessToken,
        refreshToken,
        setUser,
        code,
        setCode,
    };

    return (
        <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>
    );
};
