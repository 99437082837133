import React from 'react';
import './table.css';
import FilterableTable from 'react-filterable-table';

export default function Table(props) {
    const { headers, data } = props;

    return (
        <FilterableTable
            namespace="Users"
            initialSort="name"
            data={data}
            fields={headers}
            noRecordsMessage="There are no users to display"
            noFilteredRecordsMessage="No users match your filters!"
            topPagerVisible={false}
            iconSortedAsc={<span className='fa fa-sort-up' />}
            iconSortedDesc={<span className='fa fa-sort-down' />}
        />
    );
}
