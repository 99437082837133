import React, { useEffect, useRef } from 'react';
import { Route, Switch,
  useHistory } from 'react-router-dom';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Callback from './pages/callback/Callback';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Notification from './components/notification/notification';
import Sidebar, { ContactButton } from './components/sidebar/sidebar';
import Faq from './pages/faq/Faq';
import { Helmet } from 'react-helmet';
import { useAuthentication, useI18n } from './hooks';

import FlashMessage from './components/flash_message/flash_message';
import { DisplayMessageProvider } from './displayMessageContext';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';

import log from 'loglevel';
import Admin from './pages/admin/Admin';
import AddUser from './pages/admin/AddUser';
import EditUser from './pages/admin/EditUser';

log.setDefaultLevel('silent');
if (process.env.REACT_APP_ENV === 'development') {
  log.setLevel('trace');
}

export const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_DOMAIN,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const { i18n } = useI18n();
  const { signed_in } = useAuthentication()

  const refContainer = useRef(signed_in);
  useEffect(() => {
    refContainer.current = signed_in;
  })
  const justLoggedOut = !!refContainer.current && !signed_in;

  const history = useHistory();

  /**
   * Newer Okta requires you to add your own `restoreOriginalUri` implementation
   * To restore the uri we need `history` and for that we need to be a
   * _descendant_ of <Router>.
   *
   * @param {OktaAuth} _oktaAuth OktaOauth instance passed in by okta
   * @param {string} originalUri URI the user was on when auth was requested
   */
  async function restoreOriginalUri(_oktaAuth, originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
      <DisplayMessageProvider>
        <div>
          <Helmet>
            <title>{i18n('index.title')}</title>
            <meta
                name="description"
                content={i18n('index.meta')}>
            </meta>
            <script>
              {`(function () {
                document.querySelectorAll('iframe').forEach(function(element){
                  element.setAttribute('sandbox', 'allow-scripts allow-popups allow-forms');
                })
            })();`}
            </script>
          </Helmet>
          <Header />
          { justLoggedOut && (
            <FlashMessage message={'signed_out'}/>
          )}
          <Switch>
            <Route path='/login/callback' component={ LoginCallback } />
            <Route path='/callback' component={Callback} />
            <Route path='/faq' component={Faq} />
            <PrivateRoute path='/home' component={Home} />
            <AdminRoute exact path='/admin' component={Admin} />
            <AdminRoute exact path='/admin/add/' component={AddUser} />
            <AdminRoute exact path='/admin/user/:id' component={EditUser} />
            <Route path='/' component={Login} />
          </Switch>
          <Notification />
          <Footer />
          <ContactButton/>
          <Sidebar />
        </div>
    </DisplayMessageProvider>
  </Security>
  );
}

export default App;
