import React, { useEffect, useState } from 'react';
import { useUser, useApplications, useOktaAuthentication, useDisplayMessageContext } from '../../hooks';
import { updateUser, deleteUser } from '../../dao/user';
import { Link, useHistory, useParams } from 'react-router-dom';
import './Admin.css';
import UserForm from '../../components/admin/UserForm';

export default function EditUser() {
    const { id } = useParams();
    const history = useHistory();
    const accessToken = useOktaAuthentication();

    const applications = useApplications(accessToken, true);
    const user = useUser(id);

    const {
        setRequestResult,
        setOpenMessage,
      } = useDisplayMessageContext();

    const [ values, setValues ] = useState(
        {
            general: {
                cwsid: '',
                email: '',
                lastname: '',
                firstname: '',
                ucid: '',
                dcn: '',
                company: '',
                industry: '',
            },
            allowed: [],
        },
    );

    useEffect(() => {
        if(user) {
            setValues({
                ...values,
                general: {
                    cwsid: user?.cwsid,
                    email: user.email,
                    lastname: user?.lastname,
                    firstname: user?.firstname,
                    ucid: user?.ucid,
                    dcn: user?.dcn,
                    company: user?.company,
                    industry: user?.industry,
                },
                allowed: user.allowed,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user ]);

    const handleChange = ({
        target,
    }) => {
        setValues({ ...values, general: {
            ...values.general,
            [target.id]: target.value,
        } });
    }

    const handleAccessChange = ({
        target,
    }) => {
        if(target.checked) {
            let apps = values.allowed;
            setValues({ ...values, allowed: [ ...apps, target.id ] });
        } else {
            let filteredApps = values.allowed.filter(item => item !== target.id);
            setValues({ ...values, allowed: [ ...filteredApps ] });
        }
    }

    function handleRequestError() {
        setRequestResult({
            title: 'Error',
            message: 'An unexpected error occured',
            type: 'failure',
            time: 5000,
        });
        setOpenMessage(true);
    }

    const handleSubmit = event => {
        event.preventDefault();

        const body = {
            ...values.general,
            enabled: true,
            allowed: values.allowed,
        }

        if(accessToken) {
            updateUser(id, body, accessToken).then(
                (response) => {
                    setRequestResult({
                        title: 'Success',
                        message: response.message,
                        type: 'success',
                        time: 5000,
                    });
                    setOpenMessage(true);
                    history.push('/admin');
                },
            ).catch(handleRequestError);
        }
    }

    const handleDelete = event => {
        event.preventDefault();

        deleteUser(id, accessToken).then(
            (response) => {
                setRequestResult({
                    title: 'Success',
                    message: 'User is deleted',
                    type: 'success',
                    time: 5000,
                });
                setOpenMessage(true);
                history.push('/admin');
            },
        ).catch(handleRequestError);
    }

    return (
        <div id="admin_page">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12">
                        <Link
                            role="button" to="/admin"
                        >
                            <i className="fas fa-angle-left"></i>
                            Back to all users
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {user && (
                            <h1>Edit details for {user.email}</h1>
                        )}
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <UserForm {...{
                            edit: true,
                            handleChange,
                            values,
                        }} />
                        <div className="col-12 col-md-6">
                            <h2>Grant access to applications</h2>
                            <table>
                                <tbody>
                                    {applications && applications.map((header) => (
                                        <tr key={header.target.id}>
                                            <td>
                                                { header.target.name }
                                            </td>
                                            <td>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        onChange={handleAccessChange.bind(header.target.id)}
                                                        id={header.target.id}
                                                        name={header.target.id}
                                                        checked={
                                                        values.allowed.includes(header.target.id) && true
                                                        }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="action-row d-flex justify-content-end">
                        {user && (
                            <button type="button" className="btn btn-delete" onClick={handleDelete}>
                                Delete user
                            </button>
                        )}
                        <button type="submit" className="btn btn-yellow" value="Submit">
                            <i className="fas fa-save"></i>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}