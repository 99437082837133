import React from 'react';
import useLocalStorage from './useLocalStorage';

import en from '../i18n/en.json';
import nl from '../i18n/nl.json'

const I18nContext = React.createContext();

export const useI18n = () => {
    const context = React.useContext(I18nContext);

    if (context === undefined) {
        throw new Error(
            '`useI18n` must be used with a `I18nProvider`',
        );
    }
    return context;
};

const recursiveQuerying = (collection, key, path = []) => {
    const head = key[0];
    const tail = key.slice(1);
    if (collection[head] === undefined)
        throw  new Error(`I18n is missing a translation: ${[ ...path, ...key ].join('.')}`);
    if (tail.length === 0) {
        return collection[head];
    } else {
        return recursiveQuerying(collection[head], tail, [ ...path, head ]);
    }
};

const substitutionRE = /%\w+/;
const applySubstitutions = (translation, ...substitutions) => {
    if (substitutions.length === 0) {
        return translation;
    }
    let substituted = translation;
    for (let i = 0; i < substitutions.length; ++i) {
        substituted = substituted.replace(substitutionRE, substitutions[i]);
    }
    return substituted;
}

function detectLanguage(lang) {
    switch(lang) {
        case 'en-GB':
        case 'en-US':
        case 'en':
            return 'en';
        case 'nl-NL':
        case 'nl-BE':
        case 'nl':
            return 'nl';
        default:
            return 'en';
    }
}

export const I18nProvider = ({ children }) => {
    const [ language, setLanguage ] = useLocalStorage('language', detectLanguage(navigator.language));
    
    const languages = { en, nl };

    const i18n = (key, ...substitutions) => {
        const translation = recursiveQuerying(languages, [ language, ...key.split('.') ]);
        return applySubstitutions(translation, ...substitutions);
    }

    const context = {
        language,
        setLanguage,
        i18n,
    };

    return (
        <I18nContext.Provider value={context}>{children}</I18nContext.Provider>
    );
};
