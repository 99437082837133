import { Redirect, Route } from 'react-router-dom';
import { useAuthentication } from '../hooks';
import React from 'react';
import { ADMINS } from '../environment';

function useAdmin() {
  const { user } = useAuthentication();
  return ADMINS.includes(user?.email);
}

const PrivateRoute = (props) => {
  const { component: Component, admin = false, ...rest } = props;
  const { accessToken } = useAuthentication();
  const isAdmin = useAdmin();

  return (
    <Route
      { ...rest }
      render={ (routeProps) =>
        (accessToken && (!admin || isAdmin)) ? (
          <Component { ...routeProps } />
        ) : (
          <Redirect
            to={ {
              pathname: '/',
              state: { from: routeProps.location },
            } }
          />
        ) }
    />
  );
};

export default PrivateRoute;
