import React, { useEffect } from 'react';
import { useDisplayMessageContext } from '../../hooks/useDisplayMessageContext';
import './notification.css';

function Notification() {
  const {
    openMessage,
    requestResult,
    setOpenMessage,
  } = useDisplayMessageContext();

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
      let notificationTimer = setTimeout(() => setOpenMessage(false), requestResult?.time || 4000);

      return () => {
        clearTimeout(notificationTimer);
      };
    },
    [ openMessage, requestResult, setOpenMessage ],
  );

  const icon = (type) => {
    switch(type) {
      case 'info':
        return 'fa-info-circle';
      case 'success':
        return 'fa-check-circle';
      default:
        return 'fa-exclamation-circle';
    }
  }

  return (
    requestResult && openMessage && (
      <div className="notification">
        <div className={`notification-container ${requestResult?.type}`}>
          <button onClick={handleCloseMessage}>
            <i className="fas fa-times"></i>
          </button>
          <div className="notification-icon">
              <i className={`fas ${icon(requestResult?.type)}`}></i>
          </div>
          <div>
              <p className="notification-title">{requestResult?.title}</p>
              <p className="notification-message">{requestResult?.message}</p>
          </div>
        </div>
      </div>
    )
  );
}

export default Notification;
