import { useContext } from 'react';
import DisplayMessageContext from '../displayMessageContext';

export function useDisplayMessageContext() {
  const {
    setRequestResult,
    openMessage,
    setOpenMessage,
    requestResult,
  } = useContext(DisplayMessageContext);

  return {
    setRequestResult,
    openMessage,
    setOpenMessage,
    requestResult,
  };
}