import { useEffect, useState } from 'react';
import { fetchUserPermissions } from '../dao/user';

export function useUserPermissions(catloginid, accessToken) {
  const [ userPermissions, setUserPermissions ] = useState(null);
  const [ userObjIsLoading, setUserObjIsLoading ] = useState(true);

  useEffect(() => {
    if (accessToken) {
      fetchUserPermissions(catloginid, accessToken).then((response) => {
        // response is already the parsed JSON body, modify next line
        // to match correct property of (or whole) response
        setUserPermissions(response);
        setUserObjIsLoading(false);
      });
    }
  }, [ catloginid, accessToken ]);

  return [ userPermissions, userObjIsLoading ];
}
