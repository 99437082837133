import React from 'react';
import { useI18n } from '../../hooks';
import './flash_message.css';

export default function FlashMessage(props) {
    const { i18n } = useI18n();

    return (
        <div className={'flash-message-container'}>
            <div className={'container'}>
                <h2 className={'flash-message'}>{i18n(`flash_messages.${props.message}`)}</h2>
            </div>
        </div>
    );

}