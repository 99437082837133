import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthentication, useI18n } from '../../hooks';
import './Login.css';
import { ContactButton } from '../../components/sidebar/sidebar';
import lowResLogin from '../../media/login-header-640x430.jpg';
import medResLogin from '../../media/login-header-1920x720.jpg';
import CheckedIcon from '../../components/CheckedIcon';

function FeaturedMedia() {
    return (
        <figure className="featured-media">
            <source media="(min-width: 1200px)" srcSet={lowResLogin}  />
            <source media="(min-width: 768px)" srcSet={medResLogin}  />
            <img src={lowResLogin} alt="my-pon-power-logo"/>
        </figure>
    );
}

export default function Login() {
    const { authorize } = useAuthentication();
    const { i18n } = useI18n()

    return (
        <div>
            <div>
                <ContactButton />
            </div>
            <div id="login_page" >
                <FeaturedMedia />
                <div className="container-lg">
                    <div className="login_card row">
                        <div className="col-12 col-md-5 card-info">
                            <h1>{i18n('login.title')}</h1>
                            <p>{i18n('login.slogan')}</p>
                            <ul>
                                <li><CheckedIcon />{i18n('login.advantage_1')}</li>
                                <li><CheckedIcon />{i18n('login.advantage_2')}</li>
                                <li><CheckedIcon />{i18n('login.advantage_3')}</li>
                            </ul>
                        </div>
                        <div className="login-form button-group col-12 col-md-7">
                            <h2>{i18n('login.welcome')}</h2>
                            <p>{i18n('login.login_message')}</p>
                            <div className="column d-flex align-items-center">
                                <button className="btn btn-custom" id="login" onClick={() => authorize()}>
                                    <span>{i18n('login.login')}</span>
                                    <i className="fa fa-arrow-right"/>
                                </button>
                                <div className="signup-link">
                                    <a  id="sign-up"
                                        href="https://my.cat.com/wps/portal/customer/Auxiliary/Create-Account/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLT1cTDzcgw18_YNcjA0c_QKMvIOMDIy9HU31w_EqcDfRjyJGvwEO4GhAWH8UXiUgF4AV4LEiODVPvyA3NMIgy0QRACtuBNY!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
                                        target="_blank"
                                        rel="noopener noreferrer nofollow"
                                        >
                                        {i18n('login.sign_up')}
                                    </a>
                                </div>
                            </div>
                            <p className="m-0">
                                <a  id="forgot-password"
                                    href="https://login.cat.com/CwsLogin/cws/login.htm?showForgotPassword=true"
                                    target="_blank"
                                    rel="noreferrer nofollow noopener"
                                    >
                                    {i18n('login.forgot_password')}
                                </a>
                            </p>
                            <p className="m-0">
                                <a  id="forgot-username"
                                    href="https://my.cat.com/wps/portal/customer/Auxiliary/Forgot-Username/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLT1cTDzcgw18_YNcjA0c_QKMvIOMDIy9fU31w_EqcDfRjyJGvwEO4GhAWH8UXiUgF4AV4LGiIDc0wiDTUREAKrV97Q!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
                                    target="_blank"
                                    rel= "noreferrer nofollow noopener "
                                    >
                                    {i18n('login.forgot_username')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-lg">
                    <div className="row">
                        <div id="help_me_now" className="col-12 text-right p-0">
                            <span className="fa-stack">
                                <i className="fa fa-comment fa-stack-1x"/>
                                <i className="fa fa-question fa-stack-1x"/>
                            </span>
                            <Link id="help_me_text" to="/faq">{i18n('home.help_me_now')}</Link>
                        </div>
                    </div>
                </div>
                <div className="container-lg">
                    <div className="row about">
                        <div className="col-12">
                            <h4 className="textTitle">{i18n('login.about')}</h4>
                            <p className="text">{i18n('login.main_text')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}