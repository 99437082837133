/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 * More examples of hashing:
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * @param {string} str the input value
 * @param {integer} [asString=0] set to an `n` larger than 0 to generate a
 *   finger-print of `n` characters of the end of the base16 string of the integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
export function hashFnv32a(str, asString = 0, seed = 0x811c9dc5) {
    /*jshint bitwise:false */
    let hval = seed;

    for (let i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    //Unsign the integer (always positive)
    hval = hval >>> 0;

    if(asString){
        //Pad with 0's, toString in base 16, then take `n` characters from the end
        return ((1 << asString).toString(2) + (hval >>> 0).toString(16)).substr(-asString);
    }
    return hval;
}
