import request, {
  // Reason, used in type defitions (not code)
  // eslint-disable-next-line no-unused-vars
  SuperAgentRequest,
} from 'superagent';
import log from 'loglevel';

/**
 * A simple utility function that recursively applies all requestModifers
 * by calling them in order. (optimised for tail-end recursion)
 *
 * @private
 * @param {SuperAgentRequest} requestObject A request in the making
 * @param {requestModifier[]} modifiers An array of request modifiers
 * @returns {SuperAgentRequest} The modified request object
 */
function modifyRecursive(requestObject, modifiers) {
  if (modifiers && modifiers.length > 0) {
    return modifyRecursive(modifiers.pop()(requestObject), modifiers);
  }
  return requestObject;
}

/**
 * A simple wrapper to 'unwrap' a response, streight to body.
 *
 * @private
 * @param {SuperAgentRequest} requestObject The running request
 * @returns {Object<string, any>} Returns the body of a response
 */
async function resultHandler(requestObject) {
  return requestObject.then((response) => response.body);
}

/**
 * This type of 'callback' is used to modify an active request. It can
 * be used to add headers, or request parameters, for example.
 *
 * @callback requestModifier
 * @param {request.SuperAgentRequest} request The active request
 * @returns {request.SuperAgentRequest} the modified request
 */

/**
 * This wrapper deals with execution of any modifiers to the request.
 * It also is reponsible for injecting auth-tokens when required.
 *
 * @param {SuperAgentRequest} requestObject The current request being processed
 * @param {requestModifier[]} [modifiers] Modifier functions
 * @returns {SuperAgentRequest} The modified request.
 */
async function runRequestModifers(requestObject, modifiers = []) {
  const requestModifiers = [
    // userIdentificationModifier,
    ...modifiers,
  ].filter((mod) => !!mod);
  return await modifyRecursive(requestObject, requestModifiers);
}

/**
 * Send a request to the API, no body, no default resolve to body of response.
 *
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<request.Response>} Resolves to response, or throws on server error
 */
function createRawRequest(method, path, modifiers = []) {
  const actualRequest = runRequestModifers(request[method](path), modifiers);
  log.trace(method.toUpperCase(), path);
  return actualRequest;
}

/**
 * Send a request to the API, no body.
 *
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
function createRequest(method, path, modifiers = []) {
  return resultHandler(createRawRequest(method, path, modifiers));
}

/**
 * Send a request with body to the API.
 *
 * @private
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {*} body JSON or other type of body, depending on request type.
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
function createRequestWithBody(method, path, body, modifiers = []) {
  function bodyMod(request) {
    return request.send(body);
  }
  return createRequest(method, path, [ bodyMod, ...modifiers ]);
}

/**
 * Send a request to the API, with a JSON payload.
 *
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function createRequestWithJSON(method, path, values, modifier) {
  const body = Array.isArray(values)
    ? values
    : {
        ...values,
      };

  function contentTypeJSON(requestObject) {
    return requestObject.set('Content-Type', 'application/json');
  }

  return createRequestWithBody(method, path, body, [ modifier, contentTypeJSON ]);
}

/**
 * Send a `PATCH` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function patch(path, values, modifier) {
  return createRequestWithJSON('patch', path, values, modifier);
}

/**
 * Send a `DELETE` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function del(path, values, modifier) {
  return createRequestWithJSON('delete', path, values, modifier);
}

/**
 * Send a `PUT` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function put(path, values, modifier) {
  return createRequestWithJSON('put', path, values, modifier);
}

/**
 * Send a `POST` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function post(path, values, modifier) {
  return createRequestWithJSON('post', path, values, modifier);
}

/**
 * Send a `GET` request to the API, without payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function get(path, modifier) {
  return createRequest('get', path, [ modifier ]);
}

export {
  patch,
  put,
  del, // delete is a reserved keyword...
  post,
  get,
  createRequest,
  createRawRequest,
};
