import React from 'react';
import './header.css';
import { useAuthentication } from '../../hooks';
import { useI18n } from '../../hooks';
import { Link } from 'react-router-dom';

//Media
import logo from '../../media/logo-bordered.svg';
import flagDutch from '../../media/dutch-flag.png';
import flagBritish from '../../media/gb-flag.png';
import accountLogo from '../../media/account.png';
import loggedInLogo from '../../media/loggedin.png';

let greeting;

export default function Header() {
    const { user, authorize, logout, signed_in } = useAuthentication();

    const { language, setLanguage, i18n } = useI18n();

    if (user.given_name) {
        greeting = `${i18n('header.hello')}, ${user.given_name}`;
    }

    return (
        <header className="header-myp fixed-top navbar-custom">
            <div className="container-fluid">
                <div className="header row">
                    <div className="col-4 col-md-6">
                        <Link to={'/'}>
                            <span className="navbar-brand">
                                <img className="logo"
                                     src={logo}
                                     alt="Pon Cat"/>
                            </span>
                        </Link>

                        <a className="d-none d-lg-inline pointer" href='https://www.pon-cat.com'
                           rel="noreferrer noopener">
                            Go to pon-cat.com
                            <i className="fas fa-chevron-right"/>
                        </a>
                    </div>

                    <div id="header_buttons"
                         className="text-right row justify-content-end align-content-center">
                        { language === 'nl' &&
                            <div>
                                <img 
                                    onClick={(() => setLanguage('en'))} 
                                    className="flags"
                                    src={flagDutch}
                                    alt="Dutch flag" />
                            </div>
                        }
                        { language === 'en' &&
                            <div>
                                <img 
                                    onClick={(() => setLanguage('nl'))}
                                    className="flags"
                                    src={flagBritish}
                                    alt="British flag" />
                            </div>
                        }
                        
                        {!signed_in &&
                    
                        <div>
                            <img
                                onClick={(() => authorize())}
                                className="account-logo"
                                src={accountLogo}
                                alt="login logo" />
                        </div>
                        } 
                        {signed_in &&
                        <div>
                            <img
                                onClick={(() => authorize())}
                                className="account-logo"
                                src={loggedInLogo}
                                alt="personal account logo" />
                        </div>
                        }                    
                        { signed_in && (
                            <div className="greeting">{greeting}</div>
                        )}

                        { signed_in && (
                            <div>
                                <button id="logout_button" className="btn" onClick={() => logout()}>{i18n('header.logout')}</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}