import React from 'react';
import { useUserList } from '../../hooks';
import Table from '../../components/table/table';
import { Link } from 'react-router-dom';
import './Admin.css';

function RenderLink({
    record,
    value,
}) {
    return (
        <Link to={`/admin/user/${record.id}`}>
            {value}
        </Link>
    );
}

// Fields to show in the table, and what object properties in the data they bind to
const fields = [
    { name: 'cwsid', displayName: 'CWS ID', inputFilterable: true, sortable: true, render: RenderLink },
    { name: 'lastname', displayName: 'Last Name', inputFilterable: true, sortable: true },
    { name: 'firstname', displayName: 'First Name', inputFilterable: true, sortable: true },
    { name: 'email', displayName: 'Email', inputFilterable: true, sortable: true, render: RenderLink },
    { name: 'ucid', displayName: 'UCID Number', inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'dcn', displayName: 'DCN Number', inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'company', displayName: 'Company', inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'industry', displayName: 'Industry', inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'logins', displayName: 'Logins', sortable: true },
];

export default function Admin() {
    const users = useUserList();

    if (!users) {
        return null;
    }

    return (
        <div id="admin_page">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12">
                        <h1>Admin page</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Link
                            className="btn btn-yellow"
                            role="button" to="/admin/add"
                        >
                            <i className="fas fa-user-plus"></i>
                            Add user
                        </Link>
                        <Table headers={fields} data={users}/>
                    </div>
                </div>
            </div>
        </div>
    )
}